import React from "react";
import { useAuth } from "@arundo/adk-react";
import { navigate } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import { checkScope, GROUP_ADMIN, GROUP_READ } from "../Common/utils";

const DISPLAYED_COUNT = 3;

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: "none",
    border: `1px solid ${theme.palette.grey[300]}`
  },
  header: {
    "& span:last-child": {
      minHeight: "24px"
    },
    "& div:first-child": {
      width: "96%"
    }
  },
  subheader: {
    whiteSpace: "nowrap",
    width: "98%",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  user: {
    width: 80,
    height: 80
  },
  additional: {
    height: 60,
    width: 60
  },
  count: {
    backgroundColor: "transparent",
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.grey[300]}`
  }
}));

export const GroupCard = ({ id, name = "", description = "", users = [] }) => {
  const classes = useStyles();
  const avatars =
    users.length > DISPLAYED_COUNT ? users.slice(0, DISPLAYED_COUNT) : users;

  let { user, logoutAction } = useAuth();
  const hasGroupAdminScope = checkScope(GROUP_ADMIN, user);

  return (
    <Card className={classes.card}>
      <CardActionArea
        onClick={() => navigate(`/group/edit/${id}`)}
        disabled={!hasGroupAdminScope}
      >
        <CardHeader
          className={classes.header}
          title={<div className={classes.subheader}>{name}</div>}
          subheader={<div className={classes.subheader}>{description}</div>}
          action={<EditIcon style={{ opacity: "0.54" }} />}
        />
        <CardContent>
          {avatars.length === 0 ? (
            <Avatar
              className={`${classes.count} ${classes.user}`}
              children={0}
            />
          ) : (
            <Grid container spacing={1} alignItems="center">
              {avatars.map(user => (
                <Grid
                  item
                  key={`grid_avatar_user_id_${user.id}`}
                  children={
                    <Avatar
                      className={classes.user}
                      src={user.picture}
                      children={<PersonIcon />}
                    />
                  }
                />
              ))}
              {users.length > DISPLAYED_COUNT && (
                <Grid
                  item
                  key={`grid_avatar_count_group_${id}`}
                  children={
                    <Avatar
                      className={`${classes.count} ${classes.additional}`}
                      children={`+${users.length - DISPLAYED_COUNT}`}
                    />
                  }
                />
              )}
            </Grid>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
