import { createDataHook, getEnv } from "@arundo/adk-react";

const api = `${getEnv("CLIENT_IDENTITY_API") ||
  getEnv("AUTH0_AUDIENCE")}/api/v1/usergroups`;

export const useGroups = createDataHook(api, {
  transform: data => data,
  transformItem: group => {
    return group;
  }
});
