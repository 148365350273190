import React, { useState } from "react";
import { navigate } from "@reach/router";
import { Button, Page, useAuth, H1 } from "@arundo/adk-react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Checkbox from "@material-ui/core/Checkbox";
import ClearIcon from "@material-ui/icons/Clear";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { useGroups, useRoles } from "../../hooks";
import { GroupCard } from "./Card";
import { checkScope, GROUP_ADMIN, GROUP_READ } from "../Common/utils";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "relative",
    paddingBottom: theme.spacing(2)
  },
  toolbar: {
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: "0 0 auto"
  },
  spacer: {
    flex: "1 1 10%"
  },
  searchField: {
    paddingLeft: theme.spacing(2)
  },
  actions: {
    color: theme.palette.text.secondary
  },
  groups: {
    padding: `0 ${theme.spacing(1)}px`
  },
  noGroups: {
    margin: theme.spacing(2)
  },
  loading: {
    margin: "0 50%"
  }
}));

const SelectInput = withStyles(() => ({
  input: {
    opacity: "0.5",
    "&:focus": {
      background: "none"
    }
  }
}))(Input);

export const GroupCards = () => {
  const [selectedRoles, setSelectedRoles] = React.useState([]);
  const filtering = selectedRoles.length > 0;
  let { user, logoutAction } = useAuth();
  const hasGroupReadScope = checkScope([GROUP_READ, GROUP_ADMIN], user);
  const hasGroupAdminScope = checkScope(GROUP_ADMIN, user);
  const { data: roles = [] } = useRoles();
  const [searchString, setSearchString] = useState("");
  const searching = searchString !== "";
  const { data = [], isLoading: loadingGroups } = useGroups({
    interval: 60000,
    isCollection: false,
    query: {
      includeRoles: true
    }
  });

  let groups = data;
  const classes = useStyles();

  if (filtering) {
    groups = groups.filter(group => {
      let foundRoles = [];
      if (group.roles.some(role => selectedRoles.includes(role.name))) {
        foundRoles.push(group.name);
      }
      if (foundRoles.length === 0) {
        return false;
      }
      return true;
    });
  }

  if (searching) {
    groups = groups.filter(group => {
      let searchText = `${group.name} ${group.description}`;
      searchText =
        group.users &&
        group.users.reduce(
          (acc, { firstName, lastName, email, phoneNumber, status }) =>
            `${acc} ${firstName} ${lastName} ${email} ${phoneNumber} ${status}`,
          searchText
        );
      return searchText.toLowerCase().indexOf(searchString) >= 0;
    });
  }

  return user && !hasGroupReadScope ? (
    <Page centeredMessage>
      <h1>Sorry, {user.name} you do not have access to View Groups.</h1>
    </Page>
  ) : (
    <Page loading={loadingGroups}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.toolbar}>
          <Typography
            className={classes.title}
            variant="h4"
            children="Groups"
          />
          <div className={classes.spacer} />
          <FormControl className={classes.roleFilter}>
            <Select
              multiple
              displayEmpty
              value={selectedRoles}
              onChange={({ target: { value } }) => setSelectedRoles(value)}
              input={<SelectInput id="select-roles" />}
              renderValue={selected =>
                selected.length === 0 ? "Roles" : selected.join(", ")
              }
            >
              <MenuItem disabled value="" children="Roles" />
              {roles.map(({ id, name }) => (
                <MenuItem key={`filter_role_${id}`} value={name}>
                  <Checkbox checked={selectedRoles.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            className={classes.searchField}
            value={searchString}
            onChange={event =>
              setSearchString(event.target.value.toLowerCase())
            }
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Search">
                    <SearchIcon color="inherit" fontSize="small" />
                  </Tooltip>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={!searchString}
                    onClick={() => setSearchString("")}
                  >
                    <ClearIcon color="inherit" fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <div className={classes.actions}>
            <Tooltip title="Add a group">
              <IconButton
                onClick={() => navigate(`/group/add`)}
                children={<AddIcon />}
                disabled={!hasGroupAdminScope}
              />
            </Tooltip>
          </div>
        </Toolbar>
        <Grid container spacing={3} className={classes.groups}>
          {groups.length > 0 &&
            groups.map(group => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={`grid_group_id_${group.id}`}
                children={<GroupCard {...group} />}
              />
            ))}
          {groups.length === 0 && (
            <Grid
              item
              className={classes.noGroups}
              children={
                filtering || searching
                  ? `Sorry your search \
                      ${
                        searchString ? `for ${searchString}` : ""
                      } did not find any groups`
                  : [
                      "There are no groups",
                      <Button
                        key="button-no-groups-add"
                        color="primary"
                        style={{ margin: "0 8px" }}
                        onClick={() => navigate(`/group/add`)}
                        children="Add a group"
                        disabled={!hasGroupAdminScope}
                      />,
                      "to continue"
                    ]
              }
            />
          )}
        </Grid>
      </Paper>
    </Page>
  );
};
