import React, { memo } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { DelayedProgress } from './DelayedProgress';

const useStyles = makeStyles(() => ({
  listItemText: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const CheckList = memo(({
  data,
  loading,
  checked = [],
  toggle = () => {},
}) => {
  if (loading) {
    return (<DelayedProgress loading />);
  }

  if (!data) {
    return null;
  }

  const classes = useStyles();

  const listItemTextNoWrap = (text, variant = 'body1') => (
    <React.Fragment>
      <Typography
        className={classes.listItemText}
        variant={variant}
      >
        {text}
      </Typography>
    </React.Fragment>
  );

  return (
    <List>
      {data.map(item => {
        const {
          id,
          name,
          description,
        } = item;

        return (
          <ListItem
            key={id}
            dense
            button
            onClick={() => toggle(id)}
          >
            <ListItemIcon>
              <Checkbox checked={checked.indexOf(id) !== -1} />
            </ListItemIcon>
            <ListItemText
              primary={listItemTextNoWrap(name)}
              secondary={listItemTextNoWrap(description, 'inherit')}
            />
          </ListItem>
        );
      })}
    </List>
  );
});
