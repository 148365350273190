import React, { useCallback, useRef, useState } from "react";
import { Button } from "@arundo/adk-react";
import { navigate } from "@reach/router";
import { useFormState } from "react-use-form-state-extended";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useRoles, useUsers } from "../../hooks";
import { CheckList } from "../Common/CheckList";
import { sortByName } from "../../utils";

const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.error.main,
      borderColor: theme.palette.error.main
    }
  }
}));

export const Form = ({
  id,
  group,
  method = () => {},
  remove = () => {},
  errors = {}
}) => {
  const classes = useStyles();
  if (!group) {
    return null;
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteGroup = () => {
    remove(group).then(
      setTimeout(() => {
        // HACK: delay the navigate to allow delete to occur first
        navigate("/groups", {
          replace: true
        });
      }, 1000))
    };

  const refForm = useRef();
  const [isFormValid, setIsFormValid] = useState(true);
  const [isButtonClicked, setButtonClicked] = useState(false);

  const [formState, { text }] = useFormState(group);
  const { isLoading: loadingRoles, data: roles } = useRoles();
  const { isLoading: loadingUsers, data: users } = useUsers();
  const { roleIds, userIds } = formState.values;

  const setIds = (id, field) => {
    const checkedIds = [...formState.values[field]];
    const index = checkedIds.indexOf(id);
    index === -1 ? checkedIds.push(id) : checkedIds.splice(index, 1);
    formState.setField(field, checkedIds);
  };

  const toggleRoles = useCallback(id => setIds(id, "roleIds"), [roleIds]);
  const toggleUsers = useCallback(id => setIds(id, "userIds"), [userIds]);

  const fieldOptions = {
    margin: "normal",
    fullWidth: true
  };

  if (refForm.current) {
    refForm.current.isFormValid().then(res => {
      setIsFormValid(res);
    });
  }

  return (
    <ValidatorForm
      ref={refForm}
      onChange={e => {
        delete errors[e.target.name];
        setButtonClicked(false);
      }}
      onSubmit={e => {
        const {
          values,
          values: { name, description, roleIds, userIds }
        } = formState;

        const g = {
          name: values.name,
          description: values.description,
          roleIds: values.roleIds,
          userIds: values.userIds
        };
        // HACK: Prevent string values that did not change from being submitted
        if (name === group.name) {
          delete g.name;
        }
        if (description === group.description) {
          delete g.description;
        }
        delete group.roleIds;
        delete group.userIds;
        setButtonClicked(true);
        method(g, group);
        e.preventDefault();
      }}
    >
      <Card>
        <CardHeader
          title={id ? "Edit" : "Add"}
          component={Typography}
          variant="h4"
          disableTypography
          action={[
            id && (
              <Button
                color="secondary"
                className={classes.deleteButton}
                onClick={() =>
                  handleClickOpen()
                  /*remove(group).then(
                    setTimeout(() => {
                      // HACK: delay the navigate to allow delete to occur first
                      navigate("/groups", {
                        replace: true
                      });
                    }, 500)
                  )*/
                }
                children="Delete"
                key="deleteButton"
              />
            ),
            <Button
              color="secondary"
              onClick={() => navigate("/groups")}
              children="Cancel"
              key="cancelButton"
            />,
            <Button
              type="submit"
              color="primary"
              children="Save"
              disabled={
                !formState.hasChanges ||
                !!Object.values(errors).length ||
                !isFormValid ||
                isButtonClicked
              }
              key="saveButton"
            />
          ]}
        />
        <CardContent>
          <Typography variant="h6" children="Group" />
          <Grid container spacing={5}>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Tooltip title="Group name length should be less than 50 characters.">
                    <TextValidator
                      {...fieldOptions}
                      {...text("name")}
                      error={!!errors.name}
                      helperText={!!errors.name && errors.name}
                      label="Name"
                      required
                      validators={[
                        "minStringLength:1",
                        "maxStringLength:50",
                        "matchRegexp:^[A-Za-z0-9-_. ]+$",
                        "matchRegexp:\\S" // This checks for any whitespace (a sole char)
                      ]}
                      errorMessages={[
                        "Group name is required.",
                        "Group name length should be less than 50 characters.",
                        "Invalid name. Please try again with letters, numbers, dashs, periods or underscores.",
                        "Group name cannot be a space character, tab(s), and line break characters."
                      ]}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title="Group description length should be less than 250 characters.">
                    <TextValidator
                      {...fieldOptions}
                      {...text("description")}
                      error={!!errors.description}
                      helperText={!!errors.description && errors.description}
                      label="Description"
                      multiline
                      validators={["maxStringLength:250"]}
                      errorMessages={[
                        "Group description length should be less than 250 characters."
                      ]}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" children="Roles" />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    children="Please select the role(s) you would like this group to be part of."
                  />
                  <CheckList
                    data={roles}
                    loading={loadingRoles}
                    checked={roleIds}
                    toggle={toggleRoles}
                    field="roleIds"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" children="Users" />
              <CheckList
                data={users ? users.sort(sortByName) : []}
                loading={loadingUsers}
                checked={userIds}
                toggle={toggleUsers}
                field="userIds"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteGroup} color="primary">
            Yes
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

    </ValidatorForm>
    
  );
};
