import { createDataHook, getEnv } from "@arundo/adk-react";

const api = `${getEnv("CLIENT_IDENTITY_API") ||
  getEnv("AUTH0_AUDIENCE")}/api/v1/productscopes`;

export const useProductScopes = createDataHook(api, {
  transformItem: application => {
    application.label = application.productName;
    return application;
  }
});
