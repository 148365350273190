import React, { useState, useRef, useCallback } from "react";
import {
  message,
  Page,
  Button,
  DataTable,
  Link,
  Body2,
  useAuth
} from "@arundo/adk-react";
import { navigate } from "@reach/router";
import { useBulkInvite, useProductScopes, useGroups } from "../../hooks";
import { navigate } from "@reach/router";
import { useFormState } from "react-use-form-state-extended";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { green } from "@material-ui/core/colors";
import CsvDownloader from "react-csv-downloader";
import Tooltip from "@material-ui/core/Tooltip";
import { PhoneNumberUtil } from "google-libphonenumber";

const csv = require("csvtojson");
const validator = require("validator");

const CustomCheckCircleIcon = styled(CheckCircleIcon)({
  color: green[500]
});

const STATUS_INVALID = "Invalid";
const STATUS_VALID = "Valid";
const IMPORT_FILE_COMMENT = "#";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: "none"
  },
  icon: {
    position: "relative",
    top: theme.spacing(0.3),
    right: theme.spacing(0.6),
    width: theme.typography.h6.fontSize,
    height: theme.typography.h6.fontSize
  }
}));

const templateValues = [
  {
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@arundo.com",
    phoneNumber: "1 (111) 111-1111",
    group: "Legacy Admins",
    product: "DataSeer"
  },
  {
    firstName: "Doug",
    lastName: "Doe",
    email: "doug.doe@arundo.com",
    phoneNumber: "1 (111) 111-1112",
    group: "Support",
    product: "DataSeer"
  },
  {
    firstName: "Jamie",
    lastName: "Doe",
    email: "jamie.doe@arundo.com",
    phoneNumber: "1 (111) 111-1113",
    group: "Support",
    product: "DataSeer"
  },
  {
    firstName: IMPORT_FILE_COMMENT,
    lastName: "",
    email: "",
    phoneNumber: "",
    group: "",
    product: ""
  },
  {
    firstName: IMPORT_FILE_COMMENT + " Notes",
    lastName: "",
    email: "",
    phoneNumber: "",
    group: "",
    product: ""
  },
  {
    firstName:
      IMPORT_FILE_COMMENT +
      " firstName lastName email and product are required.",
    lastName: "",
    email: "",
    phoneNumber: "",
    group: "",
    product: ""
  },
  {
    firstName:
      IMPORT_FILE_COMMENT +
      " product must be a valid Arundo Product (DataSeer or Marathon).",
    lastName: "",
    email: "",
    phoneNumber: "",
    group: "",
    product: ""
  },
  {
    firstName: IMPORT_FILE_COMMENT + " group must be a valid Arundo Group.",
    lastName: "",
    email: "",
    phoneNumber: "",
    group: "",
    product: ""
  },
  {
    firstName:
      IMPORT_FILE_COMMENT +
      " phoneNumber must be a properly formatted Phone Number with country code # (###) ###-####.",
    lastName: "",
    email: "",
    phoneNumber: "",
    group: "",
    product: ""
  },
  {
    firstName: IMPORT_FILE_COMMENT + " email must be unique.",
    lastName: "",
    email: "",
    phoneNumber: "",
    group: "",
    product: ""
  }
];

export const Import = () => {
  const refForm = useRef();
  let { logoutAction } = useAuth();
  const [data, setData] = useState([]);
  const [fileName, setFileName] = useState("");
  let errorCount = 0;
  let fileInput;
  const [isButtonEnabled, setButtonEnabled] = useState(false);

  const {
    data: productScopes,
    isLoading: loadingProductScopes
  } = useProductScopes({
    autoload: true,
    onAuthenticationError: err => {
      message.error(`You are not authorized to view Product Scopes`);
      if (err.status == 401) logoutAction();
    }
  });

  const { data: groups, isLoading: loadingGroups } = useGroups({
    autoload: true,
    query: {
      includeRoles: false,
      includeRoleIds: false,
      includeUsers: false,
      includeUserIds: false
    },
    onAuthenticationError: err => {
      message.error(`You are not authorized to view Groups`);
      if (err.status == 401) logoutAction();
    }
  });

  const { create } = useBulkInvite({
    autoload: false,
    onError: (err = {}) => {
      const {
        detail = "An error has occured while attempting the upload.",
        ["invalid-params"]: errorMessages = {}
      } = err;
      if (errorMessages && errorMessages.email) {
        message.error(errorMessages.email);
      } else {
        message.error(detail);
      }
    },
    onCreate: response => {
      if (response.response) {
        //response.response will be an array
        let ms = "";
        const concerns = response.response.filter(
          e => e.statusCode < 200 && e.statusCode > 299
        );
        if (concerns.length > 0) {
          concerns.forEach(c => (ms += c.value.detail));
          message.error(ms);
        } else {
          message.info("Users successfully uploaded");
        }
      } else {
        message.warning("Unexpected Response from Server");
      }
    },
    onAuthenticationError: err => {
      message.error(`You are not authorized to view Create Users`);
      if (err.status == 401) logoutAction();
    }
  });

  const classes = useStyles();
  const phoneUtil = PhoneNumberUtil.getInstance();

  const validate = function(dataRow) {
    dataRow.errors = [];
    if (!dataRow.firstName) {
      dataRow.errors.push("firstName is a required field.");
    } else if (dataRow.firstName.length > 512) {
      dataRow.errors.push("firstName exceeds 512 char.");
    }
    if (!dataRow.lastName) {
      dataRow.errors.push("lastName is a required field.");
    } else if (dataRow.lastName.length > 512) {
      dataRow.errors.push("lastName exceeds 512 char.");
    }
    if (!dataRow.email) {
      dataRow.errors.push("email is a required field.");
    } else if (!validator.isEmail(dataRow.email)) {
      dataRow.errors.push("email format is not valid.");
    } else if (dataRow.email.length > 512) {
      dataRow.errors.push("email exceeds 512 char.");
    }
    if (dataRow.phoneNumber) {
      dataRow.phoneNumber = "+" + dataRow.phoneNumber;
      const phoneNumberProto = phoneUtil.parse(dataRow.phoneNumber, "");
      if (!phoneUtil.isValidNumber(phoneNumberProto)) {
        dataRow.errors.push("Invalid phoneNumber.");
      }
      if (dataRow.phoneNumber.length > 32) {
        dataRow.errors.push("phoneNumber exceeds 32 char.");
      }
    } else {
      //either = '' or does not exist - not required
      dataRow.phoneNumber = null;
    }
    if (!dataRow.group) {
      dataRow.errors.push("group is a required field.");
    } else if (groups) {
      //groupIds
      if (groups) {
        const group = groups.find(g => g.name === dataRow.group);
        if (!group) {
          dataRow.errors.push(dataRow.group + " is not a valid group name.");
        } else {
          dataRow.groupIds = [group.id];
        }
      }
    }
    if (!dataRow.product) {
      dataRow.errors.push("product is a required field.");
    } else if (productScopes) {
      const productScope = productScopes.find(
        p => p.productName === dataRow.product
      );
      if (!productScope) {
        dataRow.errors.push(dataRow.product + " is not a valid product name.");
      } else {
        dataRow.productScopeId = productScope.id;
      }
    }
    if (dataRow.errors.length) {
      dataRow.status = STATUS_INVALID;
    }
    return !Boolean(dataRow.errors.length);
  };
  const handleCapture = ({ target }) => {
    const fileReader = new FileReader();
    setFileName(target.files[0].name);

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = e => {
      errorCount = 0;

      const base64 = fileReader.result.substring(
        fileReader.result.indexOf(",") + 1
      );

      const csvData = atob(base64);
      csv({
        noheader: true,
        output: "json"
      })
        .fromString(csvData)
        .then(csvRows => {
          const dataArray = [];
          csvRows.forEach((aCsvRow, i) => {
            if (i !== 0) {
              const builtObject = {};
              Object.keys(aCsvRow).forEach(aKey => {
                const valueToAddInBuiltObject = aCsvRow[aKey];
                const keyToAddInBuiltObject = csvRows[0][aKey].replace(
                  "ï»¿",
                  ""
                );
                builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
              });
              //skip comments
              if (!builtObject.firstName.startsWith(IMPORT_FILE_COMMENT)) {
                builtObject["status"] = STATUS_VALID;
                if (!validate(builtObject)) {
                  errorCount++;
                }
                dataArray.push(builtObject);
              }
            }
          });

          setData(dataArray);
          if (errorCount > 0) {
            setButtonEnabled(true);
            message.warning(
              errorCount +
                " of " +
                dataArray.length +
                " records failed validation."
            );
          } else {
            message.info("All Import Records are valid");
          }
        });
    };
  };

  return (
    <Page>
      <ValidatorForm
        ref={refForm}
        onError={e => {
          //delete errors[e.target.name];
          console.log(e);
        }}
        onChange={e => {
          //delete errors[e.target.name];
          console.log(e);
        }}
        onSubmit={e => {
          const postData = data.filter(u => u.status === STATUS_VALID);
          if (postData.length == 0) {
            message.warning("All import records are Invalid.");
            setButtonEnabled(true);
            return;
          }
          create(postData)
            .then(() => {
              setButtonEnabled(false);
              setData([]);
              if (fileInput) fileInput.value = "";
              setFileName("");
            })
            .catch(err => {
              console.error(err);
              setButtonEnabled(false);
            });
        }}
      >
        <Card>
          <CardHeader
            title="User Import"
            component={Typography}
            variant="h4"
            disableTypography
            action={[
              <Button
                color="secondary"
                onClick={() => navigate("/")}
                children="Cancel"
                key="cancelButton"
              />,
              <Button
                type="submit"
                color="primary"
                children="Import"
                disabled={data.length == 0}
                key="saveButton"
              />
            ]}
          />
          <CardContent>
            <Typography
              variant="h6"
              children="Please select a user import file. (csv)"
            />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <input
                  type="button"
                  accept=".csv"
                  className={classes.input}
                  id="raised-button-file"
                  onChange={handleCapture}
                  type="file"
                  disabled={loadingProductScopes || loadingGroups}
                  ref={ref => (fileInput = ref)}
                />
                <label htmlFor="raised-button-file" className="button">
                  Select File
                </label>
                {fileName}
              </Grid>
              <Grid item xs={12} sm={3}>
                <CsvDownloader
                  filename="importTemplate.csv"
                  separator=","
                  datas={templateValues}
                >
                  <Button>Get Template</Button>
                </CsvDownloader>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  onClick={() => {
                    setData([]);
                    fileInput.value = "";
                    setFileName("");
                  }}
                >
                  Clear Users
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <DataTable
            data={data}
            loadingType="linear"
            localization={{
              body: {
                emptyDataSourceMessage: "No users to display"
              },
              pagination: {
                labelRowsSelect: "users"
              }
            }}
            paging
            options={{
              pageSize: 10,
              pageSizeOptions: [5, 10, 15, 20]
            }}
            selectColumns
            search
            messages
            columns={[
              { title: "First Name", field: "firstName" },
              { title: "Last Name", field: "lastName" },
              { title: "Email", field: "email" },
              { title: "Phone", field: "phoneNumber" },
              { title: "Groups", field: "group" },
              { title: "Product", field: "product" },
              {
                title: "Status",
                field: "status",
                render: row => (
                  <Tooltip title={row.errors.toString()}>
                    <Typography variant="body2" className={classes.statusField}>
                      {row.status === "Valid" ? (
                        <CustomCheckCircleIcon className={classes.icon} />
                      ) : (
                        <ErrorIcon color="error" className={classes.icon} />
                      )}
                      {row.status}
                    </Typography>
                  </Tooltip>
                )
              }
            ]}
          />
        </Card>
      </ValidatorForm>
    </Page>
  );
};
