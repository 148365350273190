import React from "react";
import Moment from "react-moment";
import { DataTable, Page, message, useAuth, H4, H1 } from "@arundo/adk-react";
import Chip from "@material-ui/core/Chip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import ThreeSixty from "@material-ui/icons/ThreeSixty";
import Typography from "@material-ui/core/Typography";
import { green, orange } from "@material-ui/core/colors";
import { makeStyles, styled } from "@material-ui/core/styles";
import { navigate } from "@reach/router";
import { MTableBodyRow, MTableToolbar } from "material-table";
import useStore from "use-store";
import { useUsers, useReInvite } from "../../hooks";
import Tooltip from "@material-ui/core/Tooltip";
import {
  checkScope,
  USERS_READ,
  USERS_CREATE,
  USERS_DELETE,
  USERS_EDIT,
  GROUP_ADMIN,
  GROUP_READ
} from "../Common/utils";

const CustomCheckCircleIcon = styled(CheckCircleIcon)({
  color: green[500]
});

const CustomWarningIcon = styled(WarningIcon)({
  color: orange[500]
});

const useStyles = currTheme =>
  makeStyles(theme => ({
    chipTextField: {
      maxWidth: "90%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    page: {
      // HACK: style table title to match groups card view
      "& h6": {
        fontSize: "1.6rem",
        fontWeight: 400,
        lineHeight: 1.17,
        letterSpacing: "0.00735em"
      },
      "& .MuiTableCell-root": {
        borderBottom: "none",
        padding: "8px 24px"
      },
      "& label + .MuiInput-formControl": {
        marginTop: 0
      }
    },
    groups: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5)
      },
      height: "fit-content",
      margin: "-5px 0px"
    },
    icon: {
      position: "relative",
      top: theme.spacing(0.3),
      right: theme.spacing(0.6),
      width: theme.typography.h6.fontSize,
      height: theme.typography.h6.fontSize
    },
    row: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      "&:hover": {
        backgroundColor:
          currTheme == "dark"
            ? theme.palette.grey[700]
            : theme.palette.grey[200]
      }
    },
    toolbar: {
      fontWeight: 600
    },
    statusField: {
      width: "max-content"
    }
  }));

const decodeNullDate = d => {
  return d == "51 years ago" ? "Never" : d;
};

export const Table = () => {
  const [theme, _] = useStore("theme");
  const classes = useStyles(theme)();

  let { user, logoutAction } = useAuth();
  const hasUserReadScope = checkScope(USERS_READ, user);
  const hasUserCreateScope = checkScope(USERS_CREATE, user);
  const hasUserDeleteScope = checkScope(USERS_DELETE, user);
  const hasUserEditScope = checkScope(USERS_EDIT, user);

  const usersDataHook = useUsers({
    onAuthenticationError: err => {
      message.error(`You are not authorized to view Users`);
      if (err.status == 401) logoutAction();
    },
    onError: (error = {}) => {
      let { detail = "A user request error has occurred." } = error;
      message.error(detail);
      logoutAction();
    },
    onUpdate: user => {
      message.info(`Updated User Status`);
    }
  });

  const { data: users = [], isLoading, update } = usersDataHook;

  const { create } = useReInvite({
    autoload: false,
    onError: (err = {}) => {
      message.error(
        err.detail
          ? err.detail
          : "An error has occured while attempting re-invitation."
      );
    },
    onCreate: response => {
      const {
        detail = response && response.ticket
          ? "User invitation sent."
          : "Possible issue with invitation."
      } = response;
      message.success(detail);
    }
  });

  const downLoadUsers = () => {
    //console.log('download!!!');
    if (!users || users.length == 0) return;
    let csv =
      "Name, Email, Invited, Last Login, Created, Phone Number, Groups\n";
    users.forEach(function(user) {
      csv += user["name"] + ",";
      csv += user["email"] + ",";
      csv += user["invitedDate"] + ",";
      csv += user["lastSuccessfulLogin"] + ",";
      csv += user["created"] + ",";
      csv += (user["phoneNumber"] || "") + ",";
      user.groups.forEach(function(group) {
        csv += group["name"] + "|";
      });
      csv += ",\n";
    });

    //console.log(csv);
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download = "users.csv";
    hiddenElement.click();
  };

  const reInviteHandler = (event, user) => {
    if (!user || !user.email || !user.firstName) return;
    create(user);
  };

  const toggleUserActive = user => {
    const status = user.status !== "Active" ? "Active" : "Inactive";
    const { id } = user;
    update({ id, status }, user);
  };

  return user && !hasUserReadScope ? (
    <Page centeredMessage>
      <h1>Sorry, {user.name} you do not have access to View Users.</h1>
    </Page>
  ) : (
    <Page className={classes.page} loading={isLoading && users.length === 0}>
      <DataTable
        dataHook={usersDataHook}
        loadingType="linear"
        localization={{
          body: {
            emptyDataSourceMessage: "No users to display"
          },
          pagination: {
            labelRowsSelect: "users"
          }
        }}
        title="Users"
        paging
        options={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 15, 20, 50, 100]
        }}
        selectColumns
        //exportEnabled
        //selection
        isDeletable={user => user.email && hasUserDeleteScope}
        onRemove={user => message.info(`Deleted  ${user.name}`)}
        columns={[
          {
            title: "Name",
            field: "name",
            filtering: false,
            defaultSort: "asc",
            cellStyle: {
              width: "20vw",
              maxWidth: "20vw",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }
          },
          {
            title: "Email",
            field: "email",
            filtering: false,
            cellStyle: {
              width: "20vw",
              maxWidth: "20vw",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }
          },
          {
            title: "Invited",
            field: "invitedDate",
            filtering: false,
            //hidden: true,
            cellStyle: {
              width: "12vw",
              maxWidth: "12vw",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            },
            render: rowData => (
              <Tooltip
                title={
                  <Moment
                    filter={decodeNullDate}
                    format="YYYY-MM-DD hh:mm"
                    date={rowData.invitedDate}
                  />
                }
              >
                <Moment
                  filter={decodeNullDate}
                  fromNow
                  date={rowData.invitedDate}
                  withTitle
                />
              </Tooltip>
            )
          },
          {
            title: "Last Login",
            field: "lastSuccessfulLogin",
            filtering: false,
            cellStyle: {
              width: "12vw",
              maxWidth: "12vw",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            },
            render: rowData => (
              <Tooltip
                title={
                  <Moment
                    filter={decodeNullDate}
                    format="YYYY-MM-DD hh:mm"
                    date={rowData.lastSuccessfulLogin}
                  />
                }
              >
                <Moment
                  filter={decodeNullDate}
                  fromNow
                  date={rowData.lastSuccessfulLogin}
                  withTitle
                />
              </Tooltip>
            )
          },
          {
            title: "Created",
            field: "created",
            filtering: false,
            cellStyle: {
              width: "12vw",
              maxWidth: "12vw",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            },
            render: rowData => (
              <Tooltip
                title={
                  <Moment
                    filter={decodeNullDate}
                    format="YYYY-MM-DD hh:mm"
                    date={rowData.created}
                  />
                }
              >
                <Moment
                  filter={decodeNullDate}
                  fromNow
                  date={rowData.created}
                  withTitle
                />
              </Tooltip>
            )
          },
          {
            title: "Groups",
            field: "groups",
            filtering: false,
            render: row => (
              <div className={classes.groups}>
                {row.groups.map(({ id, name }) => (
                  <Chip
                    className={classes.chipTextField}
                    key={`${row.id}-group-chip-${id}`}
                    label={name}
                    size="small"
                    variant="outlined"
                  />
                ))}
              </div>
            ),
            cellStyle: {
              width: "20vw",
              maxWidth: "20vw"
            }
          },
          {
            title: "Status",
            field: "status",
            filtering: false,
            lookup: {
              Active: "Active",
              Inactive: "Inactive"
            },
            render: row => (
              <Typography variant="body2" className={classes.statusField}>
                {row.status === "Active" ? (
                  <CustomCheckCircleIcon className={classes.icon} />
                ) : row.status === "Inactive" ? (
                  <ErrorIcon color="error" className={classes.icon} />
                ) : (
                  <CustomWarningIcon className={classes.icon} />
                )}
                {row.status}
              </Typography>
            ),
            cellStyle: {
              width: "5vw",
              maxWidth: "5vw"
            }
          }
        ]}
        actions={[
          {
            icon: "add",
            tooltip: "Invite User",
            isFreeAction: true,
            onClick: () => navigate(`/invite`),
            hidden: !hasUserCreateScope
          },
          {
            icon: "savealt",
            tooltip: "Export as CSV",
            isFreeAction: true,
            onClick: () => downLoadUsers(),
            hidden: !hasUserReadScope
          },
          {
            icon: "edit",
            tooltip: "Edit User",
            onClick: (event, { id }) => navigate(`/user/edit/${id}`),
            hidden: !hasUserEditScope
          },
          user => ({
            icon: "toggle_on",
            tooltip: "Deactivate",
            onClick: (event, user) => toggleUserActive(user),
            hidden:
              user.status === "Inactive" ||
              user.status === "Invited" ||
              !hasUserEditScope
          }),
          user => ({
            icon: "toggle_off",
            tooltip: "Activate",
            onClick: (event, user) => toggleUserActive(user),
            hidden:
              user.status === "Active" ||
              user.status === "Invited" ||
              !hasUserEditScope
          }),
          user => ({
            icon: "mail",
            tooltip: "Re-Invite",
            onClick: (event, user) => reInviteHandler(event, user),
            hidden: user.status !== "Invited" || !hasUserEditScope
          })
        ]}
        components={{
          Row: props => <MTableBodyRow {...props} className={classes.row} />,
          Toolbar: props => (
            <MTableToolbar {...props} className={classes.toolbar} />
          )
        }}
        search
        messages
      />
    </Page>
  );
};
