import React, { useState } from "react";
import { message, Page } from "@arundo/adk-react";
import { navigate } from "@reach/router";

import { useUsers } from "../../hooks";
import { Form } from "./Form";

export const Invite = () => {
  const [errors, setErrors] = useState({});
  const { create } = useUsers({
    onError: (error = {}) => {
      let {
        detail = "An error has occured when inviting the user.",
        ["invalid-params"]: errorMessages = {}
      } = error;

      //CORE-63
      if (
        errorMessages.email &&
        errorMessages.email.includes("already exists")
      ) {
        errorMessages.email = "User already exists";
        detail = errorMessages.email;
      } else if (errorMessages.product) {
        detail = errorMessages.product;
      }
      //CORE-63
      setErrors(errorMessages);
      message.error(detail);
    },
    onCreate: (user = {}) => {
      message.info(`Invitation sent to ${user.firstName} ${user.lastName}`);
      navigate("/");
    }
  });
  return (
    <Page>
      <Form
        method={create}
        errors={errors}
        user={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          productScopeId: "",
          groupIds: []
        }}
      />
    </Page>
  );
};
