import React, { useState } from "react";
import { message, Page } from "@arundo/adk-react";
import { navigate } from "@reach/router";

import { useUsers } from "../../hooks";
import { Form } from "./Form";

export const Edit = ({ id }) => {
  const [errors, setErrors] = useState({});
  const { isLoading = true, data: user, update: method } = useUsers(id, {
    onError: (error = {}) => {
      const {
        detail = "An error has occured when editing the user.",
        ["invalid-params"]: errorMessages = {}
      } = error;
      setErrors(errorMessages);
      message.error(detail);
    },
    onUpdate: () => navigate("/")
  });
  const formOptions = {
    id,
    user,
    method,
    errors
  };

  return (
    <Page loading={isLoading}>
      <Form {...formOptions} />
    </Page>
  );
};
