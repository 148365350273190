import React from "react";
import {
  LayoutVertical,
  Page,
  H2,
  Button,
  getEnv,
  useAuth,
  lightThemeNext as light,
  darkThemeNext as dark
} from "@arundo/adk-react";

import { Table as UserTable } from "./User/Table";
import { Edit as UserEdit } from "./User/Edit";
import { Invite } from "./User/Invite";
import { Import } from "./Import/Import";
import { GroupCards } from "./Group/Cards";
import { Edit as GroupEdit } from "./Group/Edit";
import { Add as GroupAdd } from "./Group/Add";
import {
  checkScope,
  USERS_READ,
  USERS_CREATE,
  USERS_DELETE,
  USERS_EDIT,
  GROUP_ADMIN,
  GROUP_READ
} from "./Common/utils";
import useStore from "use-store";

export default function App() {
  let { user, isLoggedIn, loginAction } = useAuth();
  const hasUserReadScope = checkScope(USERS_READ, user);
  const hasUserCreateScope = checkScope(USERS_CREATE, user);
  const hasUserEditScope = checkScope(USERS_EDIT, user);
  const hasGroupReadRights = checkScope([GROUP_READ, GROUP_ADMIN], user);
  //let [themeString, _] = useStore("theme");
  //let theme = themeString == "light" ? light : dark;
  const options = {
    logoUrl: getEnv("AUTH0_AUDIENCE"),
    //theme,
    items: [
      {
        label: "Invite",
        path: "invite",
        to: "/invite",
        component: Invite,
        authRequired: true,
        authRoles: [USERS_CREATE]
      },
      {
        label: "Users",
        path: "/",
        to: "/",
        component: UserTable,
        authRequired: true,
        authRoles: [USERS_READ]
      },
      {
        label: "Import",
        path: "/import",
        to: "/import",
        component: Import,
        authRequired: true,
        authRoles: [USERS_EDIT]
      },
      {
        path: "user/edit/:id",
        component: UserEdit,
        authRequired: true,
        authRoles: [USERS_EDIT]
      },
      {
        label: "Groups",
        path: "/groups",
        to: "/groups",
        component: GroupCards,
        authRequired: true,
        authRoles: [USERS_EDIT]
      },
      {
        path: "group/add",
        component: GroupAdd,
        authRequired: true,
        authRoles: [USERS_EDIT]
      },
      {
        path: "group/edit/:id",
        component: GroupEdit,
        authRequired: true,
        authRoles: [USERS_EDIT]
      }
    ]
  };

  return (
    <LayoutVertical {...options}>
      <Page centeredMessage>
        <H2>
          Welcome
          {isLoggedIn && user
            ? ` ${user.name}`
            : [
                " please ",
                <Button
                  key="button-app-login"
                  color="primary"
                  onClick={loginAction}
                  className={"Button-NavItem"}
                  children="Log In"
                />,
                " to continue"
              ]}
        </H2>
      </Page>
    </LayoutVertical>
  );
}
