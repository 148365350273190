export const sortByName = function(a1, a2) {
  const a = a1.name;
  const b = a2.name;
  if (a > b) {
    return 1;
  }
  if (b > a) {
    return -1;
  }
  return 0;
};
