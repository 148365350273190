import React, { useState } from "react";
import { message, Page } from "@arundo/adk-react";
import { navigate } from "@reach/router";

import { useGroups } from "../../hooks";
import { Form } from "./Form";

export const Add = () => {
  const [errors, setErrors] = useState({});
  const { create } = useGroups({
    onError: (error = {}) => {
      const {
        detail = "An error has occured when creating the group.",
        ["invalid-params"]: errorMessages = {}
      } = error;
      setErrors(errorMessages);
      message.error(detail);
    },
    onCreate: () => navigate("/groups")
  });
  const formOptions = {
    method: create,
    errors,
    group: {
      name: "",
      description: "",
      roleIds: [],
      userIds: []
    }
  };

  return (
    <Page>
      <Form {...formOptions} />
    </Page>
  );
};
