import { createDataHook, getEnv } from "@arundo/adk-react";

const api = `${getEnv("CLIENT_IDENTITY_API") ||
  getEnv("AUTH0_AUDIENCE")}/api/v3/users`;

export const useUsers = createDataHook(api, {
  transformItem: user => {
    user.name = `${user.firstName} ${user.lastName || ""}`;
    user.lastSuccessfulLogin = user.lastSuccessfulLogin
      ? new Date(user.lastSuccessfulLogin)
      : new Date("1970-01-01T00:00-0500");
    user.invitedDate = user.lastInvitedDate
      ? new Date(user.lastInvitedDate)
      : new Date("1970-01-01T00:00-0500");
    user.created = user.insertedAt
      ? new Date(user.insertedAt)
      : new Date("1970-01-01T00:00-0500");
    user.productScopeId = user.productScope ? user.productScope.id : "";
    return user;
  }
});
