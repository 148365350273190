import React, { useState } from "react";
import { message, Page } from "@arundo/adk-react";
import { navigate } from "@reach/router";

import { useGroups } from "../../hooks";
import { Form } from "./Form";

export const Edit = ({ id }) => {
  const [errors, setErrors] = useState({});
  const { isLoading = true, data: group, update: method, remove } = useGroups(
    id,
    {
      query: {
        includeRoles: false,
        includeRoleIds: true,
        includeUsers: false,
        includeUserIds: true
      },
      onError: (error = {}) => {
        const {
          detail = "An error has occured when editing the group.",
          ["invalid-params"]: errorMessages = {}
        } = error;
        setErrors(errorMessages);
        message.error(detail);
      },
      onUpdate: () => navigate("/groups")
    }
  );
  const formOptions = {
    id,
    group,
    method,
    errors,
    remove
  };

  return (
    <Page loading={isLoading}>
      <Form {...formOptions} />
    </Page>
  );
};
