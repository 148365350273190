import React, { useCallback, useRef, useState } from "react";
import { Button, Select } from "@arundo/adk-react";
import { navigate } from "@reach/router";
import { useFormState } from "react-use-form-state-extended";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { PhoneNumberUtil } from "google-libphonenumber";
import MuiPhoneNumber from "material-ui-phone-number";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { useGroups, useProductScopes } from "../../hooks";
import { CheckList } from "../Common/CheckList";

export const Form = ({ id, user, method = () => {}, errors = {} }) => {
  if (!user) {
    return null;
  }

  const refForm = useRef();
  const [isFormValid, setIsFormValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isButtonClicked, setButtonClicked] = useState(false);

  const [formState, { text, select }] = useFormState(user);

  const {
    data: productScopes,
    isLoading: loadingProductScopes
  } = useProductScopes({ autoload: true });

  const { data: groups, isLoading: loadingGroups } = useGroups({
    query: {
      includeRoles: false,
      includeRoleIds: false,
      includeUsers: false,
      includeUserIds: false
    }
  });
  const { groupIds } = formState.values;
  const setIds = (id, field) => {
    const checkedIds = [...formState.values[field]];
    const index = checkedIds.indexOf(id);
    index === -1 ? checkedIds.push(id) : checkedIds.splice(index, 1);
    formState.setField(field, checkedIds);
  };
  const toggleGroups = useCallback(id => setIds(id, "groupIds"), [groupIds]);

  const fieldOptions = {
    margin: "normal",
    fullWidth: true
  };

  const handleOnChange = val => {
    if (val === "+" || val === "") {
      formState.setField("phoneNumber", null);
    } else {
      formState.setField("phoneNumber", val);
    }

    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      if (!!val) {
        const phoneNumberProto = phoneUtil.parse(val, "");
        if (!phoneUtil.isValidNumber(phoneNumberProto)) {
          setIsPhoneValid(false);
        } else {
          setIsPhoneValid(true);
        }
      } else {
        setIsPhoneValid(true);
      }
    } catch (e) {
      if (val === "+") {
        setIsPhoneValid(true);
      } else {
        setIsPhoneValid(false);
      }
    }
  };

  if (refForm.current) {
    refForm.current.isFormValid().then(res => {
      setIsFormValid(res && isPhoneValid);
    });
  }

  return (
    <ValidatorForm
      ref={refForm}
      onChange={e => {
        delete errors[e.target.name];
        setButtonClicked(false);
      }}
      onSubmit={e => {
        setButtonClicked(true);
        const {
          values,
          values: { firstName, lastName, email, phoneNumber, productScopeId }
        } = formState;
        // HACK: Prevent string values that did not change from being submitted
        if (firstName === user.firstName) {
          delete values.firstName;
        }
        if (lastName === user.lastName) {
          delete values.lastName;
        }
        if (email === user.email) {
          delete values.email;
        }
        if (phoneNumber === user.phoneNumber) {
          delete values.phoneNumber;
        }
        if (phoneNumber === "" && !user.phoneNumber) {
          values.phoneNumber = null;
        }
        if (productScopeId === "") {
          delete values.productScopeId;
        }
        method(values, user)
          .then(() => {
            setButtonClicked(false);
          })
          .catch(err => {
            console.error(err);
            setButtonClicked(false);
          });
        e.preventDefault();
      }}
    >
      <Card>
        <CardHeader
          title={id ? "Edit" : "Invite"}
          component={Typography}
          variant="h4"
          disableTypography
          action={[
            <Button
              color="secondary"
              children="Cancel"
              key="cancelButton"
              onClick={() => navigate("/")}
            />,
            <Button
              type="submit"
              color="primary"
              children={id ? "Save" : "Invite"}
              disabled={
                !formState.hasChanges ||
                formState.values.productScopeId == "" ||
                !!Object.values(errors).length ||
                !isFormValid ||
                isButtonClicked
              }
              key="saveButton"
            />
          ]}
        />
        <CardContent>
          <Typography variant="h6" children="User" />
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <Tooltip title="User first name length should be less than 512 characters.">
                <TextValidator
                  {...fieldOptions}
                  {...text("firstName")}
                  error={!!errors.firstName}
                  helperText={!!errors.firstName && errors.firstName}
                  disabled={isButtonClicked}
                  label="First Name"
                  required
                  validators={[
                    "minStringLength:1",
                    "maxStringLength:512",
                    "matchRegexp:\\S" // This checks for any whitespace (a sole char)
                  ]}
                  errorMessages={[
                    "User first name is required.",
                    "User first name length should be less than 512 characters.",
                    "User first name cannot be a space character, tab(s), and line break characters."
                  ]}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip title="User last name length should be less than 512 characters.">
                <TextValidator
                  {...fieldOptions}
                  {...text("lastName")}
                  error={!!errors.lastName}
                  helperText={!!errors.lastName && errors.lastName}
                  label="Last Name"
                  disabled={isButtonClicked}
                  required
                  validators={[
                    "minStringLength:1",
                    "maxStringLength:512",
                    "matchRegexp:\\S" // This checks for any whitespace (a sole char)
                  ]}
                  errorMessages={[
                    "User last name is required.",
                    "User last name length should be less than 512 characters.",
                    "User last name cannot be a space character, tab(s), and line break characters."
                  ]}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip title="User email length should be less than 512 characters.">
                <TextValidator
                  {...fieldOptions}
                  {...text("email")}
                  disabled={!!id || isButtonClicked}
                  error={!!errors.email}
                  label="Email"
                  helperText={!!errors.email && errors.email}
                  required
                  validators={[
                    "minStringLength:1",
                    "isEmail",
                    "maxStringLength:512"
                  ]}
                  errorMessages={[
                    "User email is required.",
                    "User email is not valid.",
                    "User email length should be less than 512 characters."
                  ]}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip title="Please enter a phone number.">
                <MuiPhoneNumber
                  {...fieldOptions}
                  {...text("phoneNumber")}
                  defaultCountry="us"
                  disableAreaCodes
                  disabled={isButtonClicked}
                  dropdownClass="country-dropdown-list"
                  error={!!errors.phoneNumber || !isPhoneValid}
                  helperText={
                    (!!errors.phoneNumber && errors.phoneNumber) ||
                    (!isPhoneValid && "Phone Number is not valid.")
                  }
                  label="Phone"
                  onChange={handleOnChange}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" children="Product" />
              <Typography
                variant="body2"
                color="textSecondary"
                children="Please select a product for this invitation. *"
              />
              <Select
                label="Product"
                {...select("productScopeId")}
                disabled={loadingProductScopes || isButtonClicked}
                options={productScopes}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" children="Groups" />
              <Typography
                variant="body2"
                color="textSecondary"
                children="Please select the group(s) you would like this user to be part of."
              />
              <CheckList
                data={groups}
                loading={loadingGroups}
                checked={groupIds}
                toggle={toggleGroups}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ValidatorForm>
  );
};
